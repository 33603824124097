
  import { defineComponent, ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useI18n } from 'vue-i18n/index';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'ads-filter',
    emit: ['type-selected', 'reset'],
    components: {},
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      const selectedItems = ref<any>({
        status: '',
        phone: 965,
        isPaid: -1,
        price: undefined,
        size: undefined,
        packageId: '',
        propertyTypeId: '',
        propertyCategoryId: '',
        areaId: '',
        adTypeId: '',
      });
      const propertyTypesCategories = ref<any>([]);
      const propertyTypes = ref<any>([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        emit('type-selected', selectedItems);
      };
      const onReset = () => {
        selectedItems.value = {
          status: '',
          phone: 965,
          isPaid: -1,
          price: undefined,
          size: undefined,
          packageId: '',
          propertyCategoryId: '',
          propertyTypeId: '',
          areaId: '',
          adTypeId: '',
        };
        emit('reset', selectedItems.value);
      };

      watch(
        () => selectedItems.value.adTypeId,
        async () => {
          if (selectedItems.value.adTypeId) {
            await store.dispatch(
              Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID,
              { adType: selectedItems.value.adTypeId }
            );

            propertyTypesCategories.value =
              store.getters.propertyAllCategoryList;
          }
        }
      );

      watch(
        () => selectedItems.value.propertyCategoryId,
        async () => {
          if (selectedItems.value.propertyCategoryId) {
            // loading.value = true;

            await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
              type: 'ads',
              propertyTypesCategoryId: selectedItems.value.propertyCategoryId,
            });
            propertyTypes.value = store.getters.propertyTypesAllList;
          }
        }
      );

      await store.dispatch(Actions.GET_ADS_PACKAGES_LIST);

      // await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
      //   type: 'ads',
      // });
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'ads',
      });
      await store.dispatch(Actions.GET_ALL_AD_TYPES);

      reinitializeComponents();

      return {
        adTypes: computed(() => store.getters.adTypesAllList),
        adsPackages: computed(() => store.getters.adsPackagesList),
        propertyTypesCategories,
        propertyTypes,
        areas: computed(() => store.getters.allListAreas),
        onSubmit,
        onReset,
        translate,
        selectedItems,
      };
    },
  });
